import Swiper from "swiper"
import { find } from "utils/dom"

export default function () {
	const Home = find("[js-page='home']")
	if (!Home) return







	let carIniEnd = 0;
	


	


	// const videoElement = document.querySelectorAll('#video-container');
	


	// console.log(videoElement)

	



	// videoElement.forEach((videoElement) => {


	// 	console.log(videoElement)

	// 	const video = videoElement.querySelector('video')
	// 	const playButton = videoElement.querySelector('#play-button');
		
	// 	console.log(playButton)

	// 	playButton.addEventListener('click', () => {
		
	// 		video.muted = false;
	// 		playButton.classList.add('hidden'); 
	// 	});

		
	// 	let isVideoPlaying = false;

	// 	video.addEventListener('ended', () => {
	// 		console.log("chegou aqui")
	// 		video.muted = true;
	// 		video.currentTime = 0;
	// 		video.play();
	// 		playButton.classList.remove('hidden');
			

			
	
			

	// 		playButton.addEventListener('click', () => {
				
					
	// 				video.muted = false;
	// 				playButton.classList.add('hidden');
					
				
				
	// 		});




			

	// 	});

	// playButton.addEventListener('click', () => {
	// 	if (video.currentTime === video.duration) {
	// 		video.currentTime = 0; // Reinicia o vídeo
	// 	} else {
	// 		video.muted = false;
			
	// 	}
	// 	playButton.classList.add('hidden'); // Opcional: Esconde o botão após o clique
	// });





	


	// })

	


	

	



	// const videoElementSwiper = document.querySelector('.vidio-swiper');
	// const playButton2 = document.querySelector('#button-play-min');
	// const SwiperTexto = document.querySelector('.Swiper-texto')

	// console.log(videoElementSwiper)

	// //console.log(SwiperTexto)

	// playButton2.addEventListener('click', () => {
	// 	videoElementSwiper .muted = false;
	// 	playButton2.classList.add('hidden');
	// 	SwiperTexto.classList.add('hidden') 
	// });

	

	// videoElementSwiper.addEventListener('ended', () => {
	// 		videoElementSwiper.currentTime = 0
	// 		videoElementSwiper .muted = true;
	// 		playButton2.classList.remove('hidden');
	// 		SwiperTexto.classList.remove('hidden');
		
	// });

	
	const videoContainers = document.querySelectorAll('.swiper-slide');


	// console.log(videoContainers)

	
	// videoContainers.forEach((videoContainer) => {
	// 	const videoElementSwiper = videoContainer.querySelector('video');
	// 	const playButton2 = videoContainer.querySelector('#button-play-min-id'); // Use querySelector dentro do container
	// 	const swiperTexto = videoContainer.querySelector('.Swiper-texto');


	// 	// console.log(videoElementSwiper)
	// 	// console.log(playButton2)


	// 	playButton2.addEventListener('click', () => {
	// 		videoElementSwiper.muted = false;
	// 		playButton2.classList.add('hidden');
	// 		swiperTexto.classList.add('hidden');
	// 	});

	// 	videoElementSwiper.addEventListener('ended', () => {
	// 		videoElementSwiper.currentTime = 0;
	// 		videoElementSwiper.muted = true;
	// 		playButton2.classList.remove('hidden');
	// 		swiperTexto.classList.remove('hidden');
	// 	});
	// });

	
	
	


	




//retornar video



	// const videoContainer = document.getElementById('video-container');

	// const video = document.createElement('video');
	// video.setAttribute('controls', '');
	
	// const source = document.createElement('source');
	// source.setAttribute('src', '../../../../home/static/home/videos/2024.02.21 Instituto Bazzar Manifesto - horizontal H264.mp4');
	// source.setAttribute('type', 'video/mp4');
	
	// video.appendChild(source);
	// videoContainer.appendChild(video);
	



	/** Swiper Inovação | Comunicação | Crescimento */
	// const SwiperInovacaoComunicaoCrescimento = new Swiper(".Swiper-Inovacao-Comunicacao-Crescimento", {
	// 	slidesPerView: 5,
	// 	spaceBetween: 0,

	// })

	// const SwiperInovacao = new Swiper(".SwiperInovacao", {
	// 	slidesPerView: 2.5,
	// 	spaceBetween: 0,
	// 	direction: "vertical",
	// 	centeredSlides: true,
	// 	speed: 700,
	// 	loop: true,
	// 	autoplay: {
	// 		delay: 3000,
	// 	}

	// });

	// const SwiperComunicacao = new Swiper(".SwiperComunicacao", {
	// 	slidesPerView: "auto",
	// 	spaceBetween: 0,
	// 	direction: "vertical",
	// 	centeredSlides: true,
	// 	speed: 700,
	// 	loop: true,
	// 	autoplay: {
	// 		delay: 3000,
	// 	}

	// });


	

}
import axios from 'axios'
import axiosConfig from './modules/axiosConfig'
import PageHome from './pages/Home.js'
import PageLogin from "./pages/Login";
import UseMouseOrKeyboard from "./modules/UseMouseOrKeyboard";
import DesignSystem from "./DesignSystem/DesignSystem";
import { register } from 'swiper/element/bundle';
import logs from "./utils/logs";
import ripple from "./modules/ripple";
import formErros from "./modules/formErros";
import animaAoScroll from "./animation/animaAoScroll";
import animateSetup from "./animation/animateSetup";
import swiperFundadora from "./animation/animationSwiperInstituto";
import swiperTregua from "./animation/animationSwiperTregua";
import mottaEquipeText from "./modules/converteditorjs";
//import instituto from "./modules/instituto";
import cardsessao from "./modules/cardSessao01"
import Swiper from "swiper"
import MenuFunc from "./bazzar/modalMenu";
// import SvgUse from "./utils/SvgUse";

import  swiperConnect  from "./animation/swiperconecte";
import paginaContato from './modules/paginaContato';
import contatoForm from './modules/contato-conectese';
import contatoFormNewsletter from './modules/newsletter';
import fetchModal from './pages/Tregra';
import scrollToNextSection from './modules/scrollToNextSection';
import animacaoGsapManual from './animation/animacaoGsapManual';

// Init
GLOBAL.DesignSystem = DesignSystem({})
register() // Swiper Web Component

// Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)
UseMouseOrKeyboard()
ripple()
formErros()
animateSetup()
animaAoScroll()
swiperConnect()



// Pages
swiperFundadora()
swiperTregua()
PageHome()
PageLogin()?.init()
//instituto()
cardsessao()

Fancybox.bind('[data-fancybox="video-banner"]', {
    // Your custom options for a specific gallery
  });
function SwiperHome() {
    const swipercont1 = new Swiper(".swiper-container-2", {

        slidesPerView: 1.1,
        speed: 1000,
        autoHeight: true,
        
        
        // centerInsufficientSlides:true,
        // centeredSlidesBounds:true,
        slideToClickedSlide:true,
        spaceBetween: 16, 
    
        // speed: 1260, 
        // effect: 'slide', 
        // autoplay: {
        // 	delay: 200,
        // 	disableOnInteraction: true,
        // },
        


    
        navigation: {
            nextEl: '.swiper-container-2 #scrollRightBtn', 
            prevEl: '.swiper-container-2 #scrollLeftBtn', 
        },
        breakpoints:{
            540:{},
            1024:{
                slidesPerView: 2.2,
                centeredSlides: true, 
                initialSlide:1,
            }
        },
        
    });

    

    
    const swiperContainer = document.querySelector('.swiper-container-2');
    // swiperContainer.classList.add('hide-arrows');

    Fancybox.bind('[data-fancybox="carrosel-home"]', {
        // Your custom options for a specific gallery
      });
}



SwiperHome()



function SwiperProdutos() {
    const swiper_produto = new Swiper(".swiper_produto", {

        slidesPerView: 1.1,
        speed: 1000,
        autoHeight: true,
    
        slideToClickedSlide:true,
        spaceBetween: 16, 
    
        navigation: {
            nextEl: '.swiper_produto #scrollRightBtn', 
            prevEl: '.swiper_produto #scrollLeftBtn', 
        },
        breakpoints:{
            540:{},
            1024:{
                slidesPerView: 2.2,
                centeredSlides: true, 
                initialSlide:1,
            }
        },


		
    });
    

    
    const swiperContainer = document.querySelector('.swiper_produto');
    // swiperContainer.classList.add('hide-arrows');

    Fancybox.bind('[data-fancybox="carrosel-produto"]', {
        // Your custom options for a specific gallery
      });
}



SwiperProdutos()


function swiperContato(){
    const swipercont1 = new Swiper(".swiper-na-midia", {
        slidesPerView: 1.1,
        spaceBetween:32,
        breakpoints:{
            540:{
                slidesPerView: 2,
            },
            1024:{
                slidesPerView: 3,
                slidesPerGroupAuto: true,
            }
        },
        navigation: {
            nextEl: '.swiper-na-midia #scrollRightBtn', 
            prevEl: '.swiper-na-midia #scrollLeftBtn', 
        },
    })
   
}
swiperContato()

contatoForm()
contatoFormNewsletter()
paginaContato()

// utils logs

logs()
MenuFunc()


const seta = document.querySelector('.arrow-down-headers')

if(seta){
    seta.addEventListener('click',(event)=>{
        event.preventDefault();
        const href = seta.getAttribute('href');
        const section = document.querySelector(href);
        console.log(section,'aqui')
        if (section) {
            console.log(section,'aqui')
            section.scrollIntoView({ behavior: 'smooth' });
        }
    })
}
window.addEventListener('scroll', function() {
    var header = document.getElementById('menu_top');
    var scrollPosition = window.scrollY;

    if (scrollPosition > 20) {
        header.classList.add('scrolou');
    } else {
        header.classList.remove('scrolou');
    }
});

const clasMobile = document.querySelector(".clasMobile")
const clasMobile2 = document.querySelector(".clasMobile2")

if(document.documentElement.clientWidth <  601) {
	functionClasMobile(clasMobile,clasMobile2)
}

function functionClasMobile(add,nadd) {

	
	add.classList.remove('hidden')

	nadd.classList.remove('hidden')
}



// const swiperCarrossel2 = new Swiper(".swiperCarrossel2", {
// 	slidesPerView: 1, 
	
// 	slideToClickedSlide:true,
// 	autoHeight: true,


// 	pagination: {
// 		el: ".swiper-pagination",
// 		clickable: true,
// 		renderBullet: function (index, className) {
// 			return  '<span class="' + className + '">' + (index + 1) + "</span>";
// 		},
// 	},
	

	
// 		centeredSlides: true,
// 		spaceBetween: 30,
		
		
		
		
	


	



	
	

	




// });

const swiperTags = new Swiper(".swiper-tags",{
    slidesPerView: 'auto',
    spaceBetween: 16,

    navigation: {
        nextEl: '#scrollRightBtn_tags', 
    },

    breakpoints:{
        410:{
            slidesPerView: 3,
        },
        760:{
            slidesPerView: 5,
        },
        1024:{
            slidesPerView: 'auto',
           
        }
    },



	
})





// const menuLinks = document.querySelectorAll('.menu-link');

// //console.log("AQUI",menuLinks[0].href)

// function getCurrentPageUrl() {
// 	return  window.location.href.split('?page')[0];
// }

// const currentUrl = getCurrentPageUrl();

// console.log("URL atual:", currentUrl);

// console.log(menuLinks.length)


// menuLinks.forEach((link) => {
// 	console.log("eu estou aqui", link.href)
// 	if (link.href == currentUrl) {
// 			console.log(link.href);
// 			link.classList.add('active_menu_pesquise');
// 	}
// });


// let linkPagination = document.querySelectorAll('.linkPagination')

// console.log("TESTE",linkPagination)



// linkPagination.addEventListener('click', (event) =>{
// 	event.preventDefault()

// 	function getCurrentPageUrl() {
// 		return  window.location.href.split('?categoria')[1];
// 	}

// 	let valor = getCurrentPageUrl()

// 	console.log("fantasma aqui",valor)

  
// })



	const linkPagination = document.querySelectorAll('.page-list a');
	const urlparams = new URLSearchParams(window.location.search)



	

	if (urlparams ) {

		const categoria = urlparams.getAll("categoria")
		if (categoria.length > 0) {
			linkPagination.forEach((link) => {
				let newUrl = link.getAttribute("href").replace("?","&")
				
				
					newUrl += `&categoria=${categoria.join("&categoria=")}`
				
				link.href = `?${newUrl}`
	
				
	
	
	
				
		});

		}
		

	}

	



	
			// linkPagination.addEventListener('click', (event) => {
			// 		console.log("EU LEO",linkPagination)
			// });
	






	// const categoria_post = document.querySelector(".categoria_post")
	// const hidmap = document.querySelector(".hidmap")

	// console.log(categoria_post.innerHTML)

	// console.log(hidmap)


	// if(categoria_post.innerHTML == "Restaurante") {
	// 	hidmap.classList.remove('hidden')
	// }

	
	





mottaEquipeText()
fetchModal()
scrollToNextSection()



animacaoGsapManual()










